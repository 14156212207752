<template>
  <Layout>
    <div class="section-content max-w-4xl mx-auto">
      <div class="form">
        <div class="form-heading text-center mb-6">
          <h4>Confirmação de interesse da inscrição</h4>

          <h6 class="mt-4 mb-6 text-gray-700">
            Atualmente a inscrição encontra-se com status:<br />
            <b class="uppercase" v-html="situationLabel(situation)"> </b>
          </h6>

          <p
            class="bg-red-600 bg-opacity-30 text-red-800 rounded-lg p-5"
            v-html="situationJustify"
            v-if="situation === 'indeferido'"
          ></p>

          <div
            class="bg-lime-600 bg-opacity-30 text-lime-800 rounded-lg p-5"
            v-if="candidates.length > 1 && situation === 'registrado'"
            v-html="alertMultiCandidate"
          >
          </div>

          <div
            class="bg-lime-600 bg-opacity-30 text-lime-800 rounded-lg p-5"
            v-if="candidates.length === 1 && situation === 'registrado'"
            v-html="alertSingleCandidate"
          >
          </div>
        </div>

        <div class="flex flex-col sm:flex-row justify-center items-center">
          <Button
            color="white"
            background="gray-700"
            :group="true"
            @action="toLogin"
          >
            Encerrar sessão
          </Button>
          <Button
            v-if="situation === 'registrado'"
            color="white"
            background="lime-600"
            :group="true"
            @action="toChange"
          >
            Atualizar inscrição
          </Button>
          <Button
            v-if="situation === 'registrado' && visibleConclusion"
            color="white"
            background="green-700"
            @action="toConclusion"
          >
            Concluir inscrição
          </Button>
        </div>
      </div>
    </div>

    <div
      class="flex flex-col max-w-4xl mx-auto mt-5"
      v-if="candidates.length > 1"
    >
      <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div
            class="
              shadow-sm
              overflow-hidden
              border-b border-gray-200
              rounded-md
            "
          >
            <table v-if="!confirmar">
              <thead>
                <tr>
                  <th scope="col">Nome / Cargo</th>
                  <th scope="col">Notificação</th>
                  <th scope="col">Situação</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(candidate, index) in candidates" :key="index">
                  <td>
                    <div class="flex items-center">
                      <div>
                        <div class="text-md font-medium text-gray-700">
                          {{
                            candidate.fullname ? candidate.fullname : "Pendente"
                          }}
                        </div>
                        <div class="text-xs font-medium text-lime-600">
                          {{ candidate.role }}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <span :class="candidate.emailSend ? 'true' : 'false'">
                      {{ candidate.emailSend ? "Enviado" : "Não enviada" }}
                    </span>
                  </td>
                  <td>
                    <span :class="candidate.status ? 'true' : 'false'">
                      {{ candidate.status ? "Confirmado" : "Não confirmado" }}
                    </span>
                  </td>
                  <td>
                    <Button
                      v-if="!visibleConclusion"
                      color="white"
                      :background="
                        candidate.emailSend ? 'gray-700' : 'lime-600'
                      "
                      :backgroundHover="
                        candidate.emailSend ? 'gray-800' : 'lime-500'
                      "
                      :icon="true"
                      @action="openModal(candidate.id, candidate.emailSend)"
                      :disabled="allowSending(candidate)"
                    >
                      <i class="fas fa-bell"></i>
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <Modal
      :visible="visibleModal"
      :isMedia="false"
      :size="'md'"
      @emit-close-modal="closeModal"
    >
      <h1 class="text-2xl text-gray-700">Notificação por e-mail</h1>

      <hr class="mt-3 mb-5" />

      <p>
        Enviar e-mail para o candidato confirmar seus dados e sua participação.
      </p>

      <div class="-ml-2 mt-2" v-if="candidateId !== 0">
        <Button
          color="white"
          size="small"
          :background="resendEmail ? 'yellow-500' : 'lime-600'"
          @action="sendEmail(candidateId)"
        >
          {{ resendEmail ? "Reenviar e-mail" : "Enviar e-mail" }}
        </Button>
      </div>
    </Modal>

    <Modal
      :visible="visibleModalConfirmConclusion"
      :isMedia="false"
      :size="md"
      @emit-close-modal="closeModalConfirmConclusion"
    >
      <h1 class="text-2xl text-gray-700">Confirmar conclusão ?</h1>
      <hr class="mt-3 mb-5" />
      <p>
        ATENÇÃO: Ao clicar em 'Confirmar' seus dados não poderão ser mais alterados.
      </p>

      <div class="-ml-2 mt-2">
        <Button
          color="white"
          size="small"
          :background="'lime-600'"
          @action="doConfirmConclusion"
        >
          Confirmar
        </Button>
      </div>
    </Modal>

  </Layout>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";
import Modal from "@/components/elements/Modal.vue";
import services from "@/services";

export default Vue.extend({
  name: "situation",
  components: {
    Layout,
    Button,
    Modal,
  },
  computed: {
    ...mapGetters("subscription", [
      "getSituation",
      "getResponsible",
      "getCandidates",
      "getToken",
    ]),
    ...mapGetters("eleicaonet", ["getParamHotsite", "getParamSubscription"]),
  },
  data() {
    return {
      alertSingleCandidate: "",
      alertMultiCandidate: "",
      situation: "",
      situationJustify: "",
      allowedDate: "",
      visibleModal: false,
      visibleModalConfirmConclusion: false,
      visibleConclusion: false,
      candidateId: 0,
      resendEmail: false,
      responsible: {},
      candidates: [],
      confirmar: false,
      fieldsFromResponsible: [],
      filesRequired: []
    };
  },
  methods: {
    ...mapActions(["changeLoading", "sendNotification"]),
    ...mapActions("subscription", [
      "cleanDataSubscription",
      "loadDataSubscription",
      "logout",
    ]),
    situationLabel(situation) {
      let labelSituation = "";

      switch (situation) {
        case "registrado":
          labelSituation = "<span class='text-yellow-600'>NÃO CONCLUÍDA</span>";
          break;
        case "concluido":
          labelSituation = "<span class='text-lime-600'>CONCLUÍDA</span>";
          break;
        case "deferido":
          labelSituation = "<span class='text-lime-600'>DEFERIDA</span>";
          break;
        case "indeferido":
          labelSituation = "<span class='text-red-600'>INDEFERIDA</span>";
          break;
      }

      return labelSituation;
    },
    EmptyOrNull(value) {
      if (value === null || value === "") {
        return true;
      } else return false;
    },
    allowSending(candidate) {
      let pendencies = 0;
      let hasFiles = true;

      // if (this.EmptyOrNull(this.responsible.letter)) {
      //   pendencies = pendencies + 1;
      // }

      // if (this.candidates.length > 1) {
      //   if (this.EmptyOrNull(this.responsible.plateName)) {
      //     pendencies = pendencies + 1;
      //   }
      // }

      for (let i = 1; i < candidate.files.length; i++) {
        let hasUrl = candidate.files[i].url
        let required = candidate.files[i].required
        if (!hasUrl && required) {
          hasFiles = false
        }
      }

      if (pendencies > 0) return true;

      let fields = this.fieldsFromResponsible
       if (!hasFiles) return true;

      console.log('fields', JSON.stringify(fields))
      let result = (
        this.EmptyOrNull(fields['fullname'].required ? candidate.fullname : 'not required') ||
        this.EmptyOrNull(fields['rg'].required ? candidate.rg : 'not required') ||
        this.EmptyOrNull(fields['cpf'].required ? candidate.cpf : 'not required') ||
        this.EmptyOrNull(fields['maritalStatus'].required ? candidate.maritalStatus : 'not required') ||
        this.EmptyOrNull(fields['email'].required ? candidate.email : 'not required') ||
        this.EmptyOrNull(fields['phone'].required ? candidate.phone : 'not required') ||
        this.EmptyOrNull(fields['zipcode'].required ? candidate.zipcode : 'not required') ||
        this.EmptyOrNull(fields['address'].required ? candidate.address : 'not required') ||
        this.EmptyOrNull(fields['city'].required ? candidate.city : 'not required') ||
        this.EmptyOrNull(fields['district'].required ? candidate.district : 'not required') ||
        this.EmptyOrNull(fields['state'].required ? candidate.state : 'not required') ||
        this.EmptyOrNull(fields['profession'].required ? candidate.profession : 'not required') ||
        this.EmptyOrNull(fields['curriculum'].required ? candidate.curriculum : 'not required')
      )
      console.log('result ', result)
      return result
    },
    openModal(id, resendEmail) {
      this.candidateId = id;
      this.resendEmail = resendEmail;
      this.visibleModal = true;
    },
    closeModal() {
      this.candidateId = 0;
      this.resendEmail = false;
      this.visibleModal = false;
    },
    closeModalConfirmConclusion() {
      this.visibleModalConfirmConclusion = false;
    },
    async sendEmail(id) {
      this.changeLoading(true);
      const token = this.getToken.token;

      let responsible = this.getResponsible;
      let candidate = this.candidates.find((candidate) => candidate.id === id);

      await services.Subscription.sendEmail(
        token,
        responsible.fullname,
        candidate
      ).then((response) => {
        this.candidates.forEach((candidate) => {
          if (candidate.id === id) {
            candidate.emailSend = true;
          }
        });

        this.changeLoading(false);

        if (response.data.status === "falhou") {
          this.sendNotification({
            type: "error",
            message: response.data.message,
          });

          this.$router.push({ name: "SubscriptionLogin" });
        } else {
          this.sendNotification({
            type: "success",
            message: response.data.message,
          });
        }
      });

      this.candidateId = 0;
      this.resendEmail = false;
      this.visibleModal = false;
    },
    toLogin() {
      this.logout();

      this.$router.push({ name: "SubscriptionLogin" });
    },
    async refrehs(token) {
      this.cleanDataSubscription();
      return await this.loadDataSubscription(token);
    },
    async toChange() {
      this.changeLoading(true);

      const token = this.getToken.token;
      let refreshData = await this.refrehs(token);

      if (refreshData.verified) {
        this.changeLoading(false);

        this.$router.push({ name: "SubscriptionChange" });
      } else {
        this.logout();

        this.changeLoading(false);

        this.sendNotification({
          type: "error",
          message: refreshData.message,
        });

        this.$router.push({ name: "SubscriptionLogin" });
      }
    },
    async doConfirmConclusion() {
      this.visibleModalConfirmConclusion = false
      this.changeLoading(true);
      await this.doConclusion();
    },
    async toConclusion() {
      const showModalBeforeConclusion = this.getParamHotsite.pages.subscription.showModalBeforeConclusion || false
      if (showModalBeforeConclusion) {
          this.visibleModalConfirmConclusion = true
      } else {
        this.changeLoading(true);
        await this.doConclusion();
      }
    },
    async doConclusion() {

      const token = this.getToken.token;

      await services.Subscription.updateConclusion(token)
        .then(() => {
          this.changeLoading(false);

          this.situation = "concluido";

          this.sendNotification({
            type: "success",
            message:
              "Inscrição concluída com sucesso, acompanhe o status da inscrição nesse painel.",
          });
        })
        .catch((reason) => {
          this.changeLoading(false);

          const error = reason.response.data.error;
          this.sendNotification({
            type: "error",
            message: error.message,
          });
        });
    },
    formatDateString(dateString) {
      let date = new Date(dateString),
        day = date.getDate().toString(),
        dayFormat = day.length == 1 ? "0" + day : day,
        month = (date.getMonth() + 1).toString(),
        monthFormat = month.length == 1 ? "0" + month : month,
        yearFormat = date.getFullYear(),
        hora = date.getHours()
      return dayFormat + "/" + monthFormat + "/" + yearFormat + ' às ' + hora + 'h';
    },
  },
  async mounted() {
    const token = this.getToken.token;
    await this.refrehs(token);
    this.allowedDate = this.formatDateString(
      this.getParamHotsite.inscricao.ate
    );

    this.situation = this.getSituation.situation;
    this.situationJustify = this.getSituation.justify;
    this.responsible = this.getResponsible;
    this.candidates = this.getCandidates;

    let subscriptions = this.getParamSubscription.filter( (subscription) => {
      return subscription.nome === this.responsible.type.name
    })
    let subscriptionFromResponsible = subscriptions[0]
    this.fieldsFromResponsible = subscriptionFromResponsible.fields
    let filesRequired = subscriptionFromResponsible.files;
    this.filesRequired = filesRequired;
    this.confirmar = subscriptionFromResponsible.confirmar || false

    this.candidates.forEach((candidate) => {
      candidate.files.forEach((file, index) => {
        file.accept = filesRequired[index].accept;
        file.arquivo = filesRequired[index].arquivo;
        file.texto = filesRequired[index].texto;
        file.required = filesRequired[index].required
      });
    });

    this.candidates.sort((a, b) => {
      return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
    });

    let releaseConclusion = true;
    this.candidates.forEach((candidate) => {
      if (!candidate.status) {
        releaseConclusion = false;
      }
    });

    this.visibleConclusion = releaseConclusion;
    let allowedDate = this.allowedDate
    this.alertSingleCandidate = `
     <b>Você tem até ${allowedDate} para concluir sua inscrição</b>
            <br /><br />
            Para concluí-la clique no botão <b>Atualizar inscrição</b> e
            preencha todos <br />os campos obrigatórios (marcados por *).<br /><br />
            Após o preencimento de todos os dados, um novo botão será
            liberado,<br />
            onde você poderá concluir a inscrição.
    `
    this.alertMultiCandidate = `
     <b>Você tem até ${allowedDate} para concluir sua inscrição</b>
            <br /><br />
            Para concluí-la clique no botão <b>Atualizar inscrição</b> e
            preencha todos <br />os campos obrigatórios (marcados por *).<br /><br />
            Após o preenchimento, alerte os candidatos individualmente <br />
            clicando no botão
            <label class="button button-icon bg-lime-600 text-white">
              <i class="fas fa-bell"></i>
            </label>
            para que confirmem sua participação.<br /><br />

            Caso o botão não esteja ativo, verifique se todos os dados foram preenchidos.
            <br /><br />
            Com a confirmação de todos os candidatos, um novo botão será
            liberado,<br />
            onde você poderá concluir a inscrição.
    `

    if (this.getParamHotsite.pages.subscription && this.getParamHotsite.pages.subscription.alertSingleCandidate) {
      this.alertSingleCandidate = this.getParamHotsite.pages.subscription.alertSingleCandidate
    }
    if (this.getParamHotsite.pages.subscription && this.getParamHotsite.pages.subscription.alertMultiCandidate) {
      this.alertMultiCandidate = this.getParamHotsite.pages.subscription.alertMultiCandidate
    }


  },
});
</script>

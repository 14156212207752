<template>
  <transition name="fade">
    <div v-if="visible" class="modal" role="dialog" aria-modal="true">
      <div class="modal-backspace" aria-hidden="true"></div>
      <div class="modal-dialog">
        <span class="modal-center" aria-hidden="true">&#8203;</span>
        <div :class="container">
          <div :class="{ 'modal-body': true, 'padding-none': isMedia }">
            <div class="content">
              <slot></slot>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="button button-rounded text-white bg-red-600"
              @click="closeModal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Modal",
  props: {
    visible: {
      type: Boolean,
      required: true,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "md",
    },
    isMedia: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      container: {
        "modal-container": true,
        media: this.isMedia,
        sm: this.size === "sm",
        md: this.size === "md",
        lg: this.size === "lg",
      },
    };
  },
  methods: {
    closeModal() {
      this.$emit("emit-close-modal");
    },
  },
});
</script>
